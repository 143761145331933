$font-family: "Trebuchet MS", "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Tahoma, sans-serif;

body {
  background: #fcfcfc;
  color: #36495f;
}

h1 {
  font-family: $font-family;
  font-size: 42px;
  font-style: normal;
  font-variant: normal;
  font-weight: 700;
  line-height: 44px;
}
h3 {
  font-family: $font-family;
  font-size: 24px;
  font-style: normal;
  font-variant: normal;
  font-weight: 700;
  line-height: 15.4px;
}
p {
  font-family: $font-family;
  font-size: 18px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 24px;
}

label {
  display: block;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.container {
  margin: 0 auto;
  width: 612px;
  text-align: center;
  padding: 30px 0;
}

.pet {
  width: 453px;
  height: 185px;
  margin: 0 auto;
  position: relative;
}

.crop-mode .pet {
  height: 100%;
}

.pet--bone__top-left, .pet--fish__top-left {
  position: absolute;
  top: 10px;
  left: 0;
  transition: opacity .3s ease-out;
  opacity: 0;
}

.pet--bone__bottom-right, .pet--fish__bottom-right {
  position: absolute;
  bottom: 0;
  right: 0;
  transition: opacity .3s ease-out;
  opacity: 0;
}

.cat-theme {
  .pet--bone__top-left, .pet--bone__bottom-right {
    opacity: 0;
  }

  .pet--fish__top-left, .pet--fish__bottom-right {
    opacity: 1;
  }
}

.dog-theme {
  .pet--bone__top-left, .pet--bone__bottom-right {
    opacity: 1;
  }

  .pet--fish__top-left, .pet--fish__bottom-right {
    opacity: 0;
  }
}

.pet--picture {
  position: absolute;
  left: 50%;
  margin-left: -85px;
  z-index: 2;
  transition: all 0.3s ease-out;
}

.edit-mode .pet--picture {
  cursor: pointer;
}

.pet--background {
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -104px;
}

.crop-mode .pet--background {
  display: none;
}

.theme-switch {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  cursor: pointer;
}

.switch {
  width: 100px;
  height: 34px;
  border-radius: 24px;
  margin: 0 10px;
  position: relative;
  transition: background .3s ease-out;
}

.switch-label {
  font-family: $font-family;
  font-size: 20px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
}

.cat-switch, .dog-switch {
  transition-timing-function: ease-out;
  transition: opacity .3s, left .3s;
  position: absolute;
  opacity: 0;
}

.cat-switch {
  left: 0;
}

.dog-switch {
  left: 0;
}

.dog-theme {
  .dog-switch {
    opacity: 1;
    left: 60px;
  }

  .cat-switch {
    left: 60px;
  }

  .switch {
    background: #FFD3C8;
  }
}

.cat-theme {
  .cat-switch {
    opacity: 1;
    left: 0;
  }

  .dog-switch {
    left: 0;
  }

  .switch {
    background: #FFF1C1;
  }
}

.downloading .theme-switch {
  display: none;
}

.top-buttons {
  margin-bottom: 30px;
  text-align: center;
}

.downloading .top-buttons {
  display: none;
}

.preview-button {
  display: none;
}

.edit-mode {
  .preview-button {
    display: inline;
  }

  .edit-button {
    display: none;
  }
}

.top-buttons > .button-blue {
  margin-right: 48px;
}

.button {
  height: 45px;
  border-radius: 24px;
  padding: 10px 36px;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  cursor: pointer;
}

.button-blue {
  background: #609fe4;
  box-shadow: 0px 4px 0px #477bb3;
  color: #fff;
}

.button-green {
  background: #aee149;
  box-shadow: 0px 4px 0px #8cb63a;
  color: #3d3a63;
}

.pet--upload-picture-text {
  font-size: 18px;
  text-align: center;
  cursor: pointer;
  position: absolute;
  bottom: -30px;
  left: 50%;
  width: 224px;
  margin-left: -100px;
  display: none;
}

#pet--upload-picture-input {
  display: none;
}

.pet--upload-picture-button {
  color: #177def;
  text-decoration: underline;
  cursor: pointer;
}

.edit-mode {
  .pet--upload-picture-text {
    display: block;
  }

  .pet-name {
    margin-top: 48px;
  }

  .pet-info--edit-mode {
    display: block;
  }

  .pet-name {
    display: none;
  }
}

.crop-mode .pet--upload-picture-text {
  display: none;
}


.pet-name {
  text-align: center;
}

.pet-name-input {
  display: none;
  border: 1px dashed #36495f;
  box-sizing: border-box;
  border-radius: 8px;
  background: none;
  text-align: center;
  font-size: 2em;
  margin: 0.67em 0;
  font-family: $font-family;
  font-size: 42px;
  font-style: normal;
  font-variant: normal;
  font-weight: 700;
  line-height: 44px;
  margin-top: 48px;
  padding-left: 14px;
  padding-right: 14px;
}

.edit-mode .pet-name-input {
  display: inline-block;
}

.pet-info {
  position: relative;
  width: 522px;
  margin: 0 auto;
  min-height: 80px;
  border-radius: 24px;
  text-align: left;
}

.pet-info--edit-mode {
  width: 100%;
  height: 100%;
  position: absolute;
  display: none;
  border-radius: 24px;
  z-index: 1;
}

.pet-info--title {
  text-align: center;
  padding-top: 16px;
  z-index: 1;
  position: relative;
}

.edit-mode .pet-info--title {
  visibility: hidden;
}

.pet-info--title-input {
  display: none;
  text-align: center;
  border: 1px dashed #36495f;
  box-sizing: border-box;
  border-radius: 8px;
  margin: 0 auto;
  margin-top: 10px;
  font-family: $font-family;
  font-size: 24px;
  font-style: normal;
  font-variant: normal;
  font-weight: 700;
  line-height: 15.4px;
  background: none;
  padding-left: 14px;
  padding-right: 14px;
}

.edit-mode .pet-info--title-input {
  display: block;
}

.edit-mode .pet-info--title-input__huge {
  font-size: 48px;
  line-height: 48px;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-decoration: none;
  color: #36495f;
}

.pet-info--text {
  padding: 0 40px 20px 40px;
}

.pet-info--text__no-title {
  padding: 20px 40px;
  text-align: center;
}

.pet-info--text--item {
  white-space: nowrap;
  margin-right: 18px;
}

.pet-info--text-input {
  display: none;
  border: 1px dashed #5c7bf8;
  box-sizing: border-box;
  border-radius: 8px;
  background: none;
  padding: 4px;
  font-family: $font-family;
  font-size: 18px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 24px;
  margin: 8px auto;
  width: 90%;
}

.edit-mode .pet-info--text-input {
  display: block;
}

.edit-mode .pet-info--text {
  visibility: hidden;
}

.edit-mode .pet-info--text__no-title {
  visibility: visible;
}

.edit-mode .pet-name-input {
  display: inline-block;
}

.pet-green {
  background-color: #eff7e2;
  text-align: center;
}

.edit-mode .pet-green .pet-info--edit-mode {
  background-color: rgba(239, 247, 226, 0.7);
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.pet-green__elem1 {
  position: absolute;
  top: 16px;
  left: 6px;
}

.pet-green__elem2 {
  position: absolute;
  bottom: 10px;
  right: 30px;
}

.pet-green__star {
  position: absolute;
  top: 20px;
  right: 10px;
}

.pet-yellow {
  background-color: #fdf7e3;
}

.pet-yellow .pet-info--edit-mode {
  background-color: rgba(253, 247, 227, 0.7);
}

.pet-yellow__elem1 {
  position: absolute;
  top: 16px;
  left: 10px;
}

.pet-yellow__elem2 {
  position: absolute;
  top: 10px;
  right: 20%;
}

.pet-yellow__star {
  position: absolute;
  top: 20px;
  left: 30%;
}

.pet-red {
  background-color: #fbece8;
}

.pet-red .pet-info--edit-mode {
  background-color: rgba(251, 236, 232, 0.7);
}

.pet-red__elem1 {
  position: absolute;
  top: 10px;
  left: 10px;
}

.pet-red__star {
  position: absolute;
  top: 30px;
  right: 15%;
}

.contacts {
  text-align: center;
  font-size: 20px;
  color: #5c7bf8;
}

.edit-mode .contacts {
  display: none;
}

.contacts-input {
  display: none;
  text-align: center;
  border: 1px dashed #5c7bf8;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: $font-family;
  font-size: 20px;
  color: #5c7bf8;
  font-style: normal;
  font-variant: normal;
  font-weight: 700;
  line-height: 15.4px;
  padding-left: 14px;
  padding-right: 14px;
  background: none;
}

/* Do not highlight autocompleted in Safari  */
.contacts-input:-webkit-autofill,
.contacts-input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px #fcfcfc inset;
  -webkit-text-fill-color: #5c7bf8;
}

.edit-mode .contacts-input {
  display: inline-block;
}

.bottom-buttons {
  margin: 40px 0;
  text-align: center;
}

.downloading .bottom-buttons {
  display: none;
}

.bottom-buttons > .button-blue {
  margin-right: 48px;
}

#contacts-email-input {
  margin-top: 4px;
}

.modal {
  position: fixed;
  z-index: 100;
  width: 100vw;
  min-height: 100vh;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &.open {
    visibility: visible;
    opacity: 1;
    transition-delay: 0s;
  }

  &-bg {
    position: absolute;
    background: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
  }

  &-container {
    width: 522px;
    max-height: 80vh;
    border-radius: 24px;
    background: #FCFCFC;
    position: relative;
    padding: 30px;
    overflow-y: auto;
  }

  &-close {
    position: absolute;
    right: 15px;
    top: 15px;
    outline: none;
    appearance: none;
    color: red;
    background: none;
    border: 0px;
    font-weight: bold;
    cursor: pointer;
  }
}

.attribute-input {
  border: 1px dashed #5c7bf8;
  box-sizing: border-box;
  border-radius: 8px;

  background: none;
  margin: 0.67em 0;
  font-family: $font-family;
  font-size: 16px;
  font-style: normal;
  font-variant: normal;
  line-height: 20px;
  padding: 8px;
  width: 220px;
}

.attribute-value-input {
  margin-left: 22px;
}

.attribute-delete-input {
  margin-left: 20px;
}

.modal-buttons {
  margin-top: 20px;
}

.add-attribute-button {
  font-size: 20px;
  float: left;
  height: 26px;
  width: 90px;
}

.save-button {
  font-size: 20px;
  float: right;
  height: 26px;
  width: 90px;
}

.copyright {
  text-align: center;
  color: #b9b8b8;
  font-size: 14px;

  & > a {
    color: #a5a5ff;
  }
}

@media only screen and (max-width: 600px) {
  body:not(.downloading) {
    .container {
      width: 100%;
      padding: 30px 8px;
      box-sizing: border-box;
    }

    .button {
      height: 45px;
      border-radius: 24px;
      padding: 10px 20px;
      text-align: center;
      font-size: 22px;
      font-weight: 600;
      cursor: pointer;
    }


    .pet {
      width: 100%;
      height: 185px;
    }

    &.crop-mode .pet {
      height: 100%;
    }

    .pet--upload-picture-text {
      font-size: 18px;
      bottom: -30px;
      left: 50%;
      width: 224px;
      margin-left: -100px;
    }

    .pet-info {
      width: 100%;
      min-height: 80px;
    }


    .pet-info--text-input {
      width: 90%;
    }


    .pet-name-input {
      font-size: 24px;
      line-height: 34px;
      margin-top: 48px;
      padding-left: 14px;
      padding-right: 14px;
    }

    .modal {
      width: auto;
      height: auto;
      overflow-y: scroll;
      overflow-x: hidden;
    }

    .modal-container {
      width: 100%;
      min-height: 100vh;
      border-radius: 0;
      max-height: 100%;
      overflow-y: auto;
    }

    .attribute-input {
      width: 78%;
      margin: 0 auto 20px auto;
    }

    .add-attribute-button {
      height: 26px;
      width: 90px;
    }

    .save-button {
      height: 26px;
      width: 90px;
    }

    .contacts-input {
      margin-bottom: 10px;
    }

    #contacts-email-input {
      margin-top: 0;
      margin-bottom: 0;
    }

    #attributes-form > div {
      margin-top: 20px;
    }

    .attribute-delete-input {
      margin-top: -60px;
    }
  }
}
